






















import { InputSetups } from '../mixins/input-setups'
import { FlowsService } from '@/includes/services/FlowService'
import { DistributionService } from '@/includes/services/DistributionService'
import MetaDataService from '@/includes/services/MetaDataService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import BotDefaultEditorMessage from '@/components/HelpMessages/editor/BotDefaultEditorMessage.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import DistributionEditor
  from 'piramis-base-components/src/components/Distributions/DistributionEditor/DistributionEditor.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { SuccessGetUser } from 'piramis-base-components/src/components/Flow/logic/types/type'
import {
  DistributionEditorAction,
  MessageEditorProps
} from 'piramis-base-components/src/components/Distributions/DistributionEditor/types'
import { Distribution, FullDistribution } from 'piramis-base-components/src/components/Distributions/Distribution'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { systemLabelKey, SYSTEM_LABELS } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/LabelsAction/SYSTEM_LABELS'

import { Component, Mixins } from 'vue-property-decorator'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import { ChannelInfoResponse } from 'piramis-base-components/src/models/CheckChannels'

@Component({
  data() {
    return {
      DistributionEditorAction,
    }
  },
  components: {
    DistributionEditor,
  },
})
export default class DistributionItem extends Mixins(UseFields, InputSetups, PlaceholdersMixin) {
  messageEditorProps: MessageEditorProps | null = null

  utmSources: Array<{ id: number, value: string }> = []

  distribution: FullDistribution | Distribution | null = null

  get labels(): Array<SelectOption> {
    return [
      ...Object.entries(SYSTEM_LABELS)
        .map(([ k, v ]) => ({ label: this.$t(systemLabelKey(k as keyof typeof SYSTEM_LABELS)).toString(), value: v })),
      ...this.$store.getters.getLabelsOptions ]
  }

  get pageAction(): DistributionEditorAction {
    return this.$route.params.actionType as DistributionEditorAction
  }

  get isLoaded() {
    if (this.pageAction !== DistributionEditorAction.new ) {
      return !!this.distribution
    }

    return true
  }

  getChannelsInfo(values: Array<string>) {
    return FlowsService.checkChannels('tg', {
      board_key: this.$store.getters.activeBoard.board,
      values
    })
      .then(({ data }) => plainToInstance(ChannelInfoResponse, { data }).data)
      .catch(errorNotification)
  }

  getUsersInfo(values: Array<string | number>): Promise<Array<Omit<SuccessGetUser, 'status'>>> {
    return new Promise(resolve => {
      FlowsService.getUsersInfo('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, values })
        .then(({ data }) => resolve(data))
    })
  }

  preCheckDistribution(conditions: Distribution['conditions']): Promise<number> {
    return new Promise(resolve => {
      DistributionService.preCheckDistribution('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, conditions })
        .then(({ count }) => resolve(count))
    })
  }

  saveDistribution(distribution: Distribution): void {
    if (this.pageAction === DistributionEditorAction.new || this.pageAction === DistributionEditorAction.copy) {

      DistributionService.createDistribution('tg',
                                             {
                                               board_key: this.$store.state.boardsState.activeBoard!.board,
                                               distribution
                                             })
        .then(() => {
          successNotification(this.$t('success').toString())
          this.goToList()
        })
        .catch(errorNotification)
    }
    if (this.pageAction === DistributionEditorAction.edit) {
      DistributionService.editDistribution('tg',
                                           {
                                             board_key: this.$store.state.boardsState.activeBoard!.board,
                                             distribution
                                           })
        .then(() => {
          successNotification(this.$t('success').toString())
          this.goToList()
        })
        .catch(errorNotification)
    }
  }

  goToList(): void {
    this.$router.push({
      name: 'Distributions',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }

  created(): void {
    if (this.$store.state.flowsState.flows === null) {
      this.$store.dispatch('requestFlows')
    }

    if (this.$store.state.formsState.forms === null) {
      this.$store.dispatch('getForms')
    }
    
    this.messageEditorProps = {
      defaultMessageEditorButtons: this.newPmButtons,
      editorButtonsHelpView: BotDefaultEditorMessage,
      board_key: this.$store.state.boardsState.activeBoard!.board
    }

    MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard!.board })
      .then(({ items }) => this.utmSources = items)

    if (this.pageAction !== DistributionEditorAction.new) {
      const distributionId = Number.parseInt(this.$route.params.distributionId.toString())

      DistributionService.getDistribution('tg', { board_key: this.$store.state.boardsState.activeBoard!.board, id: distributionId })
        .then((data) => {
          if (this.pageAction === DistributionEditorAction.watch || this.pageAction === DistributionEditorAction.edit) {
            this.distribution = data
          } else {
            const plain = instanceToPlain(data)

            this.distribution = plainToInstance(Distribution, {
              description: plain.description,
              content: plain.content,
              conditions: plain?.conditions ?? [],
              time: plain.time,
              timezone: this.$store.state.boardsState.activeBoard?.timezone,
              limit: plain.limit
            })
          }
        })
    } else {
      this.distribution = plainToInstance(Distribution, {
        description: '',
        content: {
          templates: [ {
            text: '',
            attachments: [],
            buttons: [],
            disable_link_preview: false,
            disable_notify: false,
            pin: false,
            protect_content: false,
            remove_previous: false,
            remove_after: 0,
            send_after: 0,
          } ]
        },
        conditions: [],
        time: null,
        timezone: this.$store.state.boardsState.activeBoard?.timezone,
        limit: 0
      })
    }
  }
}
